import './BackToTopSticker1.css';


const BackToTopSticker1 = ({mountID}) => {


  /* APP STATE */
  const SCROLLY_SHOW_STICKER = 100;
  let backToTopSticker = document.getElementById('back-to-top-sticker-mount');

  /* HANDLERS */
  const handleShowSticker = () => {
    if ( window.scrollY > SCROLLY_SHOW_STICKER && backToTopSticker.style.visibility !== 'visible' ) {
      backToTopSticker.style.visibility = 'visible';
    } else if ( window.scrollY < SCROLLY_SHOW_STICKER && backToTopSticker.style.visibility !== 'hidden' ) {
      backToTopSticker.style.visibility = 'hidden';
    }
  }


  /* READY DOM */
  const readyDOM = () => {
    window.addEventListener('scroll', handleShowSticker);
  }


  /* RENDER */
  const render = () => {
    let html = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-95 0 570 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"/>
        <circle
          cx="190"
          cy="50%"
          r="250px"
          stroke="#5084ec"
          stroke-width="30"
          fill="transparent"
        />
      </svg>
    `;

    document.getElementById(mountID).innerHTML = html;
  }

  render();
  readyDOM();
}


export default BackToTopSticker1;



/* <svg>
<circle
  cx="28"
  cy="28"
  r="25"
  stroke="#5084ec"
  stroke-width="3"
  fill="transparent"
/>
<line
  x1="28"
  y1="43"
  x2="28"
  y2="12"
  stroke="#5084ec"
  stroke-width="3"
  stroke-linecap="round"
/>
<polyline 
  points="20,25 28,12 36,25"
  fill="none"
  stroke="#5084ec"
  stroke-width="3"
  stroke-linecap="round"
/>
</svg> */


/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="-110 0 610 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
<path d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"/>
<circle
  cx="190"
  cy="50%"
  r="50%"
  stroke="#5084ec"
  stroke-width="30"
  fill="transparent"
/>
</svg> */
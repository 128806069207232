// This file imports common components to be used on all pages

import Navbar1 from './components/navbars/Navabar1/Navbar1';
import BackToTopSticker1 from './components/page-stickers/BackToTopSticker1/BackToTopSticker1';
import Footer1 from './components/footers/Footer1';

import './index.css';
// import WindowLoader1 from './components/loaders/WindowLoader1';

// WindowLoader1({selectorMount: '.window-loader-mount'})


Navbar1({mountID: 'navbar-mount'});

BackToTopSticker1({mountID: 'back-to-top-sticker-mount'});

Footer1({mountID: 'footer'});

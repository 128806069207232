import QuoteBtn1 from '../../buttons/QuoteBtn1/QuoteBtn1';
import './Navbar1.css';


const Navbar1 = ( {mountID} ) => {
  // State
  let prevScrollposition = window.scrollY;

  let navMount = null;
  let navCt = null;


  // Event Handlers
  const handleToggleSideNav = () => {
    document.querySelector("#side-nav-menu").classList.toggle('side-nav-menu-active');
    document.querySelector("#sidebar-waffle-btn-ct").classList.toggle('open');
  }


  function handleFadeNavBar() {
    if (document.documentElement.scrollTop === 0) {
      if (!navCt.classList.contains('navbar-fade-out') ) {
        navCt.classList.add('navbar-fade-out');
      }
      return;
    }


    // 1. if scrolled past hero image and is currently faded out -> fade in
    // 2. if scrolled before hero image and is currently faded in -> fade out
    if ( document.documentElement.scrollTop > window.innerHeight + 80 ) {
      if ( !navCt.classList.contains('navbar-fade-in') ) {
        navCt.classList.remove('navbar-fade-out');
        navCt.classList.add('navbar-fade-in');
      }
    } else if ( document.documentElement.scrollTop <= innerHeight + 80 && navCt.classList.contains('navbar-fade-in') ) {
      navCt.classList.remove('navbar-fade-in');
      navCt.classList.add('navbar-fade-out');
    }


    let currentScrollPosition = window.scrollY;

    // 1. if you've passed the first section
    if ( document.documentElement.scrollTop > window.innerHeight + document.querySelector('.hide-navbar-here').offsetHeight ) {
      // on scroll up, show navabar
      if ( prevScrollposition > currentScrollPosition ) {
        navMount.style.top = "0";
      } else if ( document.documentElement.scrollTop > 30 ) {
        // on scroll down, hide navbar
        navMount.style.top = "-40px";
      }
    }

    prevScrollposition = currentScrollPosition;
  }



  // Add Event Listeners
  function readyDOM() {
    navCt = document.getElementById("navbar-ct");
    navMount = document.getElementById("navbar-mount");

    document.querySelector("#sidebar-waffle-btn-ct").addEventListener('click', handleToggleSideNav);
    let anchors = document.querySelectorAll("#side-nav-menu a");
    anchors.forEach( item => item.addEventListener('click', handleToggleSideNav));
  }


  // renders
  const render = () => {
    let html = `
    <!-- navbar -->
    <div class="navbar-ct" id="navbar-ct">
    
      <a class="logo-home-icon-ct" href="/">
        <img src="https://cc-img.us-southeast-1.linodeobjects.com/icon-cc-logo.svg" alt="Context Coding Logo Home Link">
      </a>

      <!-- Brand Name -->
      <a href="/" class="brand-name-in-nav-ct">
        <div>
          <span class="brand-name-in-nav"><span class="logo-brackets">&#123;</span><span class="logo-spread">...</span>Context Coding<span class="logo-brackets">&#125;</span></span>
        </div>
        <div>
          <div class="slogan-in-nav">Custom Web Designs</div>
        </div>
      </a>



      <div class="navbar-right-ct">

        <!-- Quote Button -->
        <div class="desktop-quote-btn-ct">

          <div class="quote-btn-mount">
            <!-- MOUNT -->
          </div>

        </div>


        <!-- Waffle Bar Menu -->
        <span id="sidebar-waffle-btn-ct">
          <span class="waffle-bar" ></span>
          <span class="waffle-bar" ></span>
          <span class="waffle-bar" ></span>
        </span>


        <!-- sidebar navigation active when open -->
        <div id="side-nav-menu">
          <a href="/#about">About</a>
          <a href="/#services">Services</a>
          <a href="/contact">Contact</a>
          <div class="quote-btn-mount">
            <!-- MOUNT -->
          </div>
        </div>

      </div> <!-- END navbar-right-ct -->

    </div> <!-- END navbar-ct  -->
    `;

    document.getElementById(mountID).insertAdjacentHTML('beforeend', html);

    QuoteBtn1({mountClass: 'quote-btn-mount'});
  }


  // RUN
  render();
  readyDOM();
  window.onscroll = () => handleFadeNavBar();

}

export default Navbar1;

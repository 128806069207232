// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.get-free-quote-btn-ct {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 230px;
}

.get-free-quote-btn-ct button {
  background-color: var(--btn-green1);
  color: var(--brand-white2);
  border: 3px solid transparent;
  border-radius: 50px;
  padding: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: .8px;
}

.get-free-quote-btn-ct button:hover {
  cursor: pointer;
  color: var(--brand-white1);
  border: 3px solid var(--brand-green1);
}


/* The button in the sidebar-nav-menu */
#side-nav-menu .get-free-quote-btn-ct {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}



@media (max-width: 710px) {
  /* remove desktop btn */
  .navbar-ct .desktop-quote-btn-ct .get-free-quote-btn-ct {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/buttons/QuoteBtn1/QuoteBtn1.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,mCAAmC;EACnC,0BAA0B;EAC1B,6BAA6B;EAC7B,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,0BAA0B;EAC1B,qCAAqC;AACvC;;;AAGA,uCAAuC;AACvC;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;;;;AAIA;EACE,uBAAuB;EACvB;IACE,aAAa;EACf;AACF","sourcesContent":[".get-free-quote-btn-ct {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  max-width: 230px;\n}\n\n.get-free-quote-btn-ct button {\n  background-color: var(--btn-green1);\n  color: var(--brand-white2);\n  border: 3px solid transparent;\n  border-radius: 50px;\n  padding: 5px;\n  font-size: 1.2rem;\n  font-weight: bold;\n  letter-spacing: .8px;\n}\n\n.get-free-quote-btn-ct button:hover {\n  cursor: pointer;\n  color: var(--brand-white1);\n  border: 3px solid var(--brand-green1);\n}\n\n\n/* The button in the sidebar-nav-menu */\n#side-nav-menu .get-free-quote-btn-ct {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 30px;\n}\n\n\n\n@media (max-width: 710px) {\n  /* remove desktop btn */\n  .navbar-ct .desktop-quote-btn-ct .get-free-quote-btn-ct {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { email, phone } from '../../modules/commonData';
import './Footer1.css';


const Footer1 = ({mountID}) => {

  const render = () => {

    let thisYear = new Date().getFullYear().toString()  // returns the current year
    let html = `
    <footer>
      <h2>Contact Context<span class="spread">...</span></h2>

      <!-- ICON CT This is where i had put social media icons/links -->
      <div class="footer-icons-ct"> 


      </div> <!-- END ICON CT -->

      <div class="contact-info-ct">
        <a href="mailto:${email}?subject = Inquiry&body = Hi Chase! I have a great idea for a website that I want to run past you!">
          <span title="Click To Call">${email}</span>
        </a>
        <a href="tel:${phone}">
          <span title="Click To Call">${phone}</span>
        </a>
      </div>

      <div class="footer-copyright">© 2021-${thisYear} Context Coding. All Rights Reserved.</div>

    </footer>
    `;
    document.getElementById(mountID).insertAdjacentHTML('beforeend', html);
  }

  render();
}

export default Footer1;

import './QuoteBtn1.css';
import { phone } from "../../../modules/commonData";


const QuoteBtn1 = ( {mountClass} ) => {
  

  const render = () => {
    let html = `
      <div class="get-free-quote-btn-ct">
        <a href="/contact"><button>FREE DEMO PAGE</button></a>
        <div class="phone-number-ct">
          <a class="phone-number-link" href="tel:${phone}">
            <span title="Click To Call">${phone}</span>
          </a>
        </div>
      </div>
    `;

    for (const el of document.getElementsByClassName(mountClass)) {
      el.innerHTML = html 
    }

  }

  render();
}


export default QuoteBtn1;
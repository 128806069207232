// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#back-to-top-sticker-mount {
  display: flex;
  justify-content: center;
  height: 56px;
  width: 56px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  z-index: 99;
  border-radius: 50%;
  visibility: hidden;
  fill: white;
  stroke: var(--brand-dark1);
  stroke-width: 10;
  stroke-linecap: round;
}

#back-to-top-sticker-mount:hover {
  fill: red;
  stroke: red;
}

#back-to-top-sticker-mount circle:hover {
  stroke: #0a66c2;
}
`, "",{"version":3,"sources":["webpack://./src/components/page-stickers/BackToTopSticker1/BackToTopSticker1.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,eAAe;EACf,WAAW;EACX,YAAY;EACZ,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,0BAA0B;EAC1B,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,SAAS;EACT,WAAW;AACb;;AAEA;EACE,eAAe;AACjB","sourcesContent":["#back-to-top-sticker-mount {\n  display: flex;\n  justify-content: center;\n  height: 56px;\n  width: 56px;\n  position: fixed;\n  right: 10px;\n  bottom: 10px;\n  cursor: pointer;\n  z-index: 99;\n  border-radius: 50%;\n  visibility: hidden;\n  fill: white;\n  stroke: var(--brand-dark1);\n  stroke-width: 10;\n  stroke-linecap: round;\n}\n\n#back-to-top-sticker-mount:hover {\n  fill: red;\n  stroke: red;\n}\n\n#back-to-top-sticker-mount circle:hover {\n  stroke: #0a66c2;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
